/* Global Styles */
body, .landing-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Make sure the parent container takes the full height of the viewport */
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #000435;
  color: white;
  overflow-x: hidden;
  overflow-y: auto;
  background-image: url('%PUBLIC_URL%/../../../BG.jpg'); /* Ensure the background covers the entire area */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* Ensure landing-page and all containers respect viewport width */
.landing-page,
.form-container-main,
.shared-details-container,
.email-mfa-container {
  width: 100%;
  max-width: 100vw; /* Prevent them from overflowing horizontally */
  padding: 20px;
  box-sizing: border-box; /* Include padding in element width */
}


/* Landing Page Styles */
.landing-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  padding: 20px 0;
}

/* Form Container */
.form-container-main {
  background-color: #000435;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
}

.logo-image {
  max-width: 100px; /* Set a max-width for the image */
  width: 100%;
  margin-bottom: 20px; /* Add some space below the image */
  display: block;
  margin-left: auto;
  margin-right: auto; /* Ensures the image is centered */
}



/* Form Heading */
.form-container h1 {
  font-size: 22px;
  color: #e0e0e0;
}

.form-container p {
  font-size: 1rem;
  margin-bottom: 2rem;
  color: #e0e0e0;
}

/* Input Fields */
input {
  width: 100%;
  padding: 12px;
  margin: 2px 0;
  border: none;
  border-radius: 5px;
  background-color: white;
  color: black;
  font-size: 12px;
  box-sizing: border-box; /* Ensure padding doesn't affect width */
}

/* Buttons */
button {
  padding: 12px;
  width: 100%;
  border: none;
  border-radius: 5px;
  background-color: #0159fc;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.4s ease, color 0.4s ease; /* Smooth color transition */

}



button:hover {
  background-color: #00e6b8;
  color: black;
}

.copy-btn {
  margin-top:10px;
  padding: 12px;
  width: 100%;
  border: none;
  border-radius: 5px;
  background-color: #0159fc;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.4s ease, color 0.4s ease; /* Smooth color transition */

}

.copy-btn:hover {
  background-color: #00e6b8;
  color: black;
}

.custom-link {
  color: #1EC192; /* Change to any color you like */
  text-decoration: none; /* Optional: removes the underline */
  margin-bottom: 15px;;
}

.custom-link:hover {
  color: #3877AC; /* Optional: hover effect */
}


/* ShareForm Specific Styles */
.share-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: stretch;
  width: 100%;
}

.credential-set {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.add-btn, .remove-btn {
  background-color: #0159fc;
  color: white;
  padding: 12px 16px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: fit-content;
  align-self: flex-start;
  transition: background-color 0.4s ease, color 0.4s ease; /* Smooth color transition */

}

.add-btn:hover, .remove-btn:hover {
  background-color: #00e6b8;
  color: black;
}

.share-btn {
  padding: 16px;
  background-color: #0159fc;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.2rem;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.4s ease, color 0.4s ease; /* Smooth color transition */

}

.share-btn:hover {
  background-color: #00e6b8;
  color: black;
}

/* Share Link */
.share-link {
  color: white;
  text-align: center;
}

.share-link p {
  margin: 0;
  padding: 10px 0;
  font-size: 1rem;
}





/* Shared Details Page */

.shared-details-container {
  padding: 50px;
  background-color: #000435;
  color: white;
  border-radius: 10px;
  width: 400px;
  max-width: 600px;
  margin: 50px auto; /* Auto for horizontal centering and margin for spacing from top */
  text-align: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Add shadow for better visual */
}

.shared-details-container h1 {
  font-size: 22px;
  color: #e0e0e0;
}

.credential-set-shared {
  background-color: #3a3b72;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  color: white;
}

.credential-set-shared h3 {
  font-size: 1.3rem;
  color: white;
}

.credential-set-shared p {
  margin: 0.5rem 0;
  font-size: 1rem;
}

.session-countdown {
  font-size: 1.2rem;
  color: white;
  text-align: center;
  margin-top: 35px;
}

.error-message {
  color: red;
  font-size: 1.2rem;
  text-align: center;
}




/* Email and Authentication code Entry Page */

/* Email and Authentication code Entry Page */
.email-mfa-container {
  padding: 50px;
  background-color: #000435;
  color: white;
  border-radius: 10px;
  max-width: 400px;
  width: 400px;
  text-align: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}



.email-mfa-container h1 {
  font-size: 22px;
  color: #e0e0e0;
}

.email-mfa-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.email-mfa-form input {
  width: 100%;
  padding: 12px;
  margin: 2px 0;
  border: none;
  border-radius: 5px;
  background-color: white;
  color: black;
  font-size: 12px;
}

.email-mfa-form button {
  padding: 12px;
  width: 100%;
  border: none;
  border-radius: 5px;
  background-color: #0159fc;
  color: white;
  font-weight: bold;
  cursor: pointer;
  font-size: 1.1rem;
  transition: background-color 0.4s ease, color 0.4s ease; /* Smooth color transition */

}

.email-mfa-form button:hover {
  background-color: #00e6b8;
}

.error-message {
  color: red;
  font-size: 1.2rem;
  margin-top: 10px;
}


/* Container to hold all the cards */
.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;  /* Space between cards */
}

/* Styling each credential card */
.credential-card {
  background-color: #3a3b72;
  color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  max-width: 350px;  /* Limit the width of each card */
  width: 100%;  /* Full width on smaller screens */
  text-align: left;
  transition: transform 0.2s ease-in-out; /* Add a hover effect */
  transition: background-color 0.4s ease, color 0.4s ease; /* Smooth color transition */

}

/* Add some hover effect to make the cards interactive */
.credential-card:hover {
  transform: scale(1.05);  /* Slight zoom on hover */
}

/* Heading and Paragraph */
.form-container-main h1 {
  font-size: 22px;
  color: #e0e0e0;
}

.form-container-main h2 {
  font-size: 14px;
  margin-bottom: 1rem;
  color: #e0e0e0;
}

.form-container-main p {
  font-size: 14px;
  margin-bottom: 1rem;
  color: #e0e0e0;
}


/* Responsive adjustments */
@media (max-width: 768px) {
  .form-container-main {
    max-width: 100%;
    margin:35px;
  }

  .shared-details-container,
  .email-mfa-container {
    width: 90%;
    padding: 15px;
  }

  .cards-container {
    flex-direction: column; /* Stack cards vertically on small screens */
    align-items: center;
}

}


.form-container-main {
  max-height: 100vh;
  overflow-y: auto;
}


.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Adjust this value as needed */
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* Spinner Style */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 50px auto;
}

@keyframes spin {
  to {
      transform: rotate(360deg);
  }
}

/* Progress Bar Style */
.progress-bar-container {
  width: 100%;
  height: 20px;
  background-color: #f3f3f3;
  border-radius: 10px;
  margin: 50px auto;
  padding: 3px;
}

.progress-bar {
  height: 100%;
  width: 0;
  background-color: #4caf50;
  border-radius: 10px;
  transition: width 0.4s ease;
}

